<template>
  <v-container fluid>
    <!-- Welcome & Search Section (side by side on desktop) -->
    <v-row class="mb-6">
      <!-- Welcome Card (left side) -->
      <v-col cols="12" md="6">
        <v-card class="welcome-card h-100" elevation="4">
          <v-card-text class="text-center py-6">
            <div class="greeting-text">
              <h1 class="text-h3 font-weight-bold mb-2">
                {{ greeting }}, {{ currentUser.username }}!
              </h1>
              <p class="text-h6 mb-4">{{ welcomeMessage }}</p>
              <v-chip class="ma-1" color="white" label text-color="primary">
                <v-icon left>mdi-clock-outline</v-icon>
                {{ currentTime }}
              </v-chip>
              <v-chip class="ma-1" color="white" label text-color="primary">
                <v-icon left>mdi-calendar</v-icon>
                {{ currentDate }}
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Advanced Search Card (right side) -->
      <v-col cols="12" md="6">
        <v-card elevation="2" class="h-100">
          <v-card-title class="primary white--text">
            <v-icon left color="white">mdi-magnify</v-icon>
            Advanced Search
          </v-card-title>
          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="advancedSearch.keyword"
                  label="Search by keyword"
                  placeholder="Case ID, Vehicle No, etc"
                  prepend-icon="mdi-text-search"
                  clearable
                  outlined
                  hide-details="auto"
                  class="mb-3"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="advancedSearch.dept"
                  :items="departmentsForFilter"
                  item-text="name"
                  item-value="id"
                  label="Department"
                  prepend-icon="mdi-domain"
                  outlined
                  clearable
                  hide-details="auto"
                  class="mb-3"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="advancedSearch.branch"
                  :items="branchesForFilter"
                  item-text="name"
                  item-value="id"
                  label="Branch"
                  prepend-icon="mdi-office-building"
                  outlined
                  clearable
                  hide-details="auto"
                  class="mb-3"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="advancedSearch.insurer"
                  :items="insurers"
                  item-text="name"
                  item-value="id"
                  label="Insurer"
                  prepend-icon="mdi-shield-account"
                  outlined
                  clearable
                  hide-details="auto"
                  class="mb-3"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="advancedSearch.status"
                  :items="stagesForFilter"
                  item-text="name"
                  item-value="stageCode"
                  label="Status"
                  prepend-icon="mdi-flag"
                  outlined
                  clearable
                  hide-details="auto"
                  class="mb-3"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex justify-end">
                <v-btn
                  color="primary"
                  class="mr-2"
                  @click="performAdvancedSearch"
                  :loading="searchLoading"
                  :disabled="!isAdvancedSearchValid"
                >
                  <v-icon left>mdi-magnify</v-icon>
                  Search
                </v-btn>
                <v-btn text @click="resetAdvancedSearch">Clear</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- User Profile and Quick Access Section -->
    <v-row>
      <!-- User Profile Section -->
      <v-col cols="12" md="4">
        <v-card elevation="2" class="h-100">
          <v-card-title class="primary white--text">
            <v-icon left color="white">mdi-account-circle</v-icon>
            Profile Information
          </v-card-title>
          <v-card-text class="mt-4">
            <v-list dense>
              <v-list-item v-if="currentUser.fullname">
                <v-list-item-icon>
                  <v-icon>mdi-card-account-details</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    currentUser.fullname
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ currentUser.email }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-shield-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Roles</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip
                      v-for="role in currentUser.roles"
                      :key="role"
                      class="mr-2 mt-2"
                      small
                      color="primary"
                      text-color="white"
                    >
                      {{ role }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="currentUser.branches && currentUser.branches.length > 0"
              >
                <v-list-item-icon>
                  <v-icon>mdi-office-building</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Assigned Branches</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip
                      v-for="branch in branchNames"
                      :key="branch.id"
                      class="mr-2 mt-2"
                      small
                      color="secondary"
                      text-color="white"
                    >
                      {{ branch.name }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="currentUser.dept && currentUser.dept.length > 0"
              >
                <v-list-item-icon>
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Assigned Departments</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip
                      v-for="dept in deptNames"
                      :key="dept.id"
                      class="mr-2 mt-2"
                      small
                      color="secondary"
                      text-color="white"
                    >
                      {{ dept.name }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Quick Access Section -->
      <v-col cols="12" md="8">
        <v-card elevation="2">
          <v-card-title class="primary white--text">
            <v-icon left color="white">mdi-lightning-bolt</v-icon>
            Quick Access
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="(item, index) in quickAccessItems"
                :key="index"
                cols="12"
                sm="6"
                md="4"
              >
                <v-card
                  :to="item.route"
                  hover
                  outlined
                  class="text-center pa-4"
                >
                  <v-icon size="48" color="primary">{{ item.icon }}</v-icon>
                  <div class="title mt-2">{{ item.title }}</div>
                  <div class="caption grey--text">{{ item.description }}</div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BranchDataService from "../services/BranchDataService";
import DeptDataService from "../services/DeptDataService";
import StagesDataService from "../services/StageDataService"; // Add this import
import InsDataService from "../services/InsDataService"; // Add this line

export default {
  name: "Welcome",
  data() {
    return {
      currentTime: "",
      currentDate: "",
      clockInterval: null,
      branches: [],
      departments: [], // Add this line
      quickAccessItems: [
        {
          title: "Casefiles",
          icon: "mdi-folder-multiple",
          description: "View all casefiles",
          route: "/home",
        },
        {
          title: "Listing",
          icon: "mdi-format-list-bulleted",
          description: "View by Category",
          route: "/listing",
        },
        {
          title: "Profile",
          icon: "mdi-account-circle",
          description: "Your profile",
          route: "/profile",
        },
        {
          title: "Basic Reports",
          icon: "mdi-clipboard-file",
          description: "View basic reports",
          route: "/reports",
        },
        {
          title: "Detail Reports",
          icon: "mdi-clipboard-search",
          description: "View detailed reports",
          route: "/reportsTwo",
        },
        {
          title: "Claims",
          icon: "mdi-cash-multiple",
          description: "Manage claims",
          route: "/claims",
        },
      ],
      // Add advanced search properties
      searchLoading: false,
      advancedSearch: {
        keyword: "",
        dept: null,
        branch: null,
        status: null,
        insurer: null, // Add this line
      },
      stages: [], // For filtering by status
      insurers: [], // Add this line
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    branchNames() {
      return this.currentUser.branches
        .map((branchId) => this.branches.find((b) => b.id === branchId))
        .filter((branch) => branch); // Filter out any undefined values
    },
    deptNames() {
      return this.currentUser.dept
        .map((deptId) => this.departments.find((d) => d.id === deptId))
        .filter((dept) => dept);
    },
    greeting() {
      const hour = new Date().getHours();
      if (hour < 12) return "Good Morning";
      if (hour < 17) return "Good Afternoon";
      return "Good Evening";
    },
    welcomeMessage() {
      const messages = [
        "Welcome to your Document Management Workspace",
        "Here's your personalized workspace",
        "Your document control center awaits",
        "Managing documents made simple",
        "Access all your documents in one place",
        "Streamline your document workflow today",
        "Your digital document hub is ready",
        "Efficiently manage your documents here",
        "Welcome to your secure document portal",
      ];
      return messages[Math.floor(Math.random() * messages.length)];
    },
    // Add computed properties for the advanced search
    departmentsForFilter() {
      if (!this.departments || this.departments.length === 0) return [];

      const user = this.$store.state.auth.user;
      if (!user) return [];

      // If user has admin, branch manager, or clerk role, show all departments
      if (
        user.roles.some((role) => ["ADMIN", "MANAGER", "CLERK"].includes(role))
      ) {
        return [{ id: "all", name: "All Departments" }, ...this.departments];
      }

      // Otherwise, filter departments based on user's department access
      const userDepts = Array.isArray(user.dept) ? user.dept : [user.dept];

      const filteredDepts = this.departments.filter((dept) =>
        userDepts.some((userDept) => userDept === dept.id)
      );

      return [{ id: "all", name: "All Departments" }, ...filteredDepts];
    },

    branchesForFilter() {
      if (!this.branches || this.branches.length === 0) return [];

      const user = this.$store.state.auth.user;
      if (!user) return [];

      // If user has admin, branch manager, manager, or clerk role, show all branches
      if (
        user.roles.some((role) => ["ADMIN", "MANAGER", "CLERK"].includes(role))
      ) {
        return [{ id: "all", name: "All Branches" }, ...this.branches];
      }

      // Otherwise, filter branches based on user's branch access
      const userBranches = Array.isArray(user.branches)
        ? user.branches
        : [user.branches];

      const filteredBranches = this.branches.filter((branch) =>
        userBranches.some((userBranch) => userBranch === branch.id)
      );

      return [{ id: "all", name: "All Branches" }, ...filteredBranches];
    },

    stagesForFilter() {
      if (!this.stages || this.stages.length === 0) return [];
      const filteredStages = this.stages.filter(
        (stage) => stage.stageCode && stage.name
      );
      return [{ stageCode: "all", name: "All Statuses" }, ...filteredStages];
    },

    isAdvancedSearchValid() {
      return (
        this.advancedSearch.keyword ||
        this.advancedSearch.dept ||
        this.advancedSearch.branch ||
        this.advancedSearch.status ||
        this.advancedSearch.insurer // Add this line
      );
    },
  },
  methods: {
    retrieveBranches() {
      BranchDataService.getAll()
        .then((response) => {
          this.branches = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveDepartments() {
      DeptDataService.getAll()
        .then((response) => {
          this.departments = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveStages() {
      StagesDataService.getAll()
        .then((response) => {
          this.stages = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveInsurers() {
      InsDataService.getAll()
        .then((response) => {
          this.insurers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateDateTime() {
      const now = new Date();
      this.currentTime = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }).format(now);

      this.currentDate = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(now);
    },
    async performAdvancedSearch() {
      if (!this.isAdvancedSearchValid) return;

      this.searchLoading = true;

      try {
        const query = {};
        const searchParams = {};

        if (this.advancedSearch.keyword) {
          query.keyword = this.advancedSearch.keyword;
          searchParams.keyword = this.advancedSearch.keyword;
        }

        if (this.advancedSearch.dept && this.advancedSearch.dept !== "all") {
          query.dept = this.advancedSearch.dept;
          searchParams.dept = this.advancedSearch.dept;
        }

        if (
          this.advancedSearch.branch &&
          this.advancedSearch.branch !== "all"
        ) {
          query.branch = this.advancedSearch.branch;
          searchParams.branch = this.advancedSearch.branch;
        }

        if (
          this.advancedSearch.status &&
          this.advancedSearch.status !== "all"
        ) {
          query.status = this.advancedSearch.status;
          searchParams.status = this.advancedSearch.status;
        }

        if (this.advancedSearch.insurer) {
          query.insurer = this.advancedSearch.insurer;
          searchParams.insurer = this.advancedSearch.insurer;
        }

        // Save current search criteria to localStorage
        localStorage.setItem(
          "lastSearchCriteria",
          JSON.stringify(searchParams)
        );

        await this.$router.push({
          name: "advancedSearch",
          query,
        });
      } catch (error) {
        console.error("Search error:", error);
      } finally {
        this.searchLoading = false;
      }
    },

    resetAdvancedSearch() {
      this.advancedSearch = {
        keyword: "",
        dept: null,
        branch: null,
        status: null,
        insurer: null, // Add this line
      };
      // Clear saved search criteria
      localStorage.removeItem("lastSearchCriteria");
    },

    // Add this method to load saved search criteria
    loadSavedSearchCriteria() {
      try {
        const savedCriteria = localStorage.getItem("lastSearchCriteria");
        if (savedCriteria) {
          const criteria = JSON.parse(savedCriteria);
          this.advancedSearch = {
            keyword: criteria.keyword || "",
            dept: criteria.dept || null,
            branch: criteria.branch || null,
            status: criteria.status || null,
            insurer: criteria.insurer || null, // Add this line
          };
        }
      } catch (e) {
        console.error("Error loading saved search criteria:", e);
      }
    },
  },
  mounted() {
    this.retrieveBranches();
    this.retrieveDepartments();
    this.retrieveStages(); // Add this line to load stages
    this.retrieveInsurers(); // Add this line
    this.updateDateTime();
    this.clockInterval = setInterval(this.updateDateTime, 60000); // Update every minute

    // Load saved search criteria instead of recent searches
    this.loadSavedSearchCriteria();
  },
  beforeDestroy() {
    if (this.clockInterval) clearInterval(this.clockInterval);
  },
};
</script>

<style scoped>
.welcome-card {
  background: linear-gradient(135deg, #1976d2 0%, #64b5f6 100%);
  position: relative;
  overflow: hidden;
  height: 100%; /* Ensure full height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
}

.welcome-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  pointer-events: none;
}

.greeting-text {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.greeting-text h1 {
  color: white;
  letter-spacing: 0.5px;
}

.greeting-text p {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 400;
}

.h-100 {
  height: 100%;
}

/* Add these new styles for the advanced search section */
.welcome-card + .v-row .v-card {
  transition: all 0.3s ease;
}

/* Ensure inputs in advanced search look consistent */
.v-input__slot {
  min-height: 40px !important;
}

.v-chip {
  margin: 4px !important;
}

.v-chip--close .v-icon {
  font-size: 16px;
  margin-right: -6px;
}

/* Responsive adjustments for mobile */
@media (max-width: 960px) {
  .welcome-card {
    margin-bottom: 16px;
  }

  .greeting-text h1 {
    font-size: 1.75rem !important;
  }
}
</style>
